<template>
  <div>
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <!--  <b-alert
          variant="danger"
           v-model="errorShow"
          dismissible
        >
         <div class="alert-body">
            <span>{{message}}</span>
          </div>
        </b-alert>  -->
        <!-- Login v1 -->
        <b-card
          class="mb-0"
          footer-tag="footer"
        >
          <b-link
            class="brand-logo"
            to="/login"
          >
            <feather-icon
              icon="LockIcon"
              class="mr-50"
            />
            <h2 class="brand-text text-primary">
              AnyPay
            </h2>
          </b-link>
          <b-card-title class="mb-1">
            Two-Factor Verification
          </b-card-title>
          <!-- form -->
          <b-card-text
            v-if="!send_otp"
            class="mb-2"
          >
            A verification OTP will we send to +{{ mobile }} please click on SEND button to send OTP
          </b-card-text>
          <b-card-text
            v-if="send_otp"
            class="mb-2"
          >
            {{ secureMesg }}
          </b-card-text>
          <validation-observer
            v-if="send_otp"
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- phonr -->
              <b-form-group
                label-for="otp"
                label="Your Verification Code"
              >
                <validation-provider
                  #default="{ errors }"
                  name="otp"
                  rules="required|min:6"
                >
                  <b-form-input
                    id="Otp"
                    v-model="otp"
                    name="login-otp"
                    :state="errors.length > 0 ? false:null"
                    placeholder="6-digit code"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- submit button -->
              <b-button
                variant="primary"
                type="button"
                block
                @click="validateOtp"
              >
                <feather-icon
                  icon="LockIcon"
                  class="mr-50"
                />
                <span class="align-middle">Sign in Securely</span>

              </b-button>
            </b-form>
          </validation-observer>
          <template #footer>
            <b-row>
              <b-col
                sm="8"
                md="6"
                lg="6"
              >
                <b-button
                  variant="danger"
                  type="button"
                  block
                  @click="logOut"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col
                sm="8"
                md="6"
                lg="6"
              >
                <b-button
                  v-if="!send_otp"
                  variant="primary"
                  type="button"
                  block
                  @click="requetOTP"
                >
                  Send OTP
                </b-button>
                <b-button
                  v-else
                  variant="primary"
                  type="button"
                  block
                  @click="requetOTP"
                >
                  Resend OTP
                </b-button>
              </b-col>
            </b-row>
          </template>
        </b-card>
        <!-- /Login v1 -->
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SecureLS from 'secure-ls'
import {
  BButton,
  BForm,
  BCol,
  BRow,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BCardText,
  BAlert,
} from 'bootstrap-vue'
import { required } from '@validations'
import UserService from '../../services/user.service'

const ls = new SecureLS({
  encodingType: 'aes',
  isCompression: false,
})
export default {
  components: {
    // BSV
    BButton,
    BForm,
    BCol,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BRow,
    BAlert,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      otp: '',
      errorShow: false,
      user: '',
      send_otp: false,
      message: '',
      secureMesg: '',
      mobile: '',
      is_new_user: '',
      required,
    }
  },
  computed: {

  },
  created() {
    this.send_otp = ls.get('send_otp')
    this.secureMesg = ls.get('Smessage')
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      UserService.getUserInfo().then(
        response => {
          this.user = response
          const { mobile } = response
          const mobileFirst = response.mobile.substring(0, 2)
          const mobileSecond = response.mobile.substring(mobile.length - 4, 16)
          this.mobile = `${mobileFirst}-XXX-XX-${mobileSecond}`
          this.is_new_user = response.is_new_user
        },
        error => {},
      )
    },
    async requetOTP() {
      this.loading = true
      this.send_otp = true
      this.$store.dispatch('sendTwoFactorCode', { functionName: 'Verify Otp', functionCode: 'Wl' }).then(
        response => {
          this.error = false
          ls.set('secureWord', response.secureWord)
          ls.set('send_otp', true)
          ls.set('Smessage', response.message + response.secureWord)
          this.loading = false
          this.secureMesg = response.message + response.secureWord
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'OTP',
              text: 'OTP Send',
              icon: 'LockIcon',
              variant: 'success',
            },
          })
        },
        error => {
          this.loading = false
          const self = this
          if (error.response.errors != undefined) {
            Object.entries(error.response.data.errors).map((value, key) => {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  text: value[1][0],
                  icon: 'LockIcon',
                  variant: 'danger',
                },
              })
            })
          } else {
            this.errorShow = true
            this.message = error.response.data.message
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OTP',
                text: error.response.data.message,
                icon: 'LockIcon',
                variant: 'danger',
              },
            })
          }
        },
      )
    },
    async validateOtp() {
      const ls = new SecureLS({
        encodingType: 'aes',
        isCompression: false,
      })

      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('verifyTwoFactorCode', { code: this.otp, secureWord: ls.get('secureWord'), functionCode: 'Wl' }).then(
            response => {
              if (response.success) {
                this.$router.push('/dashboard').then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${response.data.name}`,
                      icon: 'UnlockIcon',
                      variant: 'success',
                      text: 'You have successfully logged!',
                    },
                  })
                })
              } else {
                this.errorShow = true
                this.message = response.message
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'OTP',
                    text: response.message,
                    icon: 'LockIcon',
                    variant: 'danger',
                  },
                })
              }
            },
            error => {
              this.errorShow = true
              this.message = error.response.data.message
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'OTP',
                  text: error.response.data.message,
                  icon: 'LockIcon',
                  variant: 'danger',
                },
              })
            },
          )
        }
      })
    },
    logOut() {
      this.$store.dispatch('logout')
      this.$router.push('/login')
    },

  },

}

</script>
<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>
